.mainSection {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c6baba;
    padding: 20px;
    margin-top: 100px;
}

.para {
    font-size: 15px;
    font-family: sans-serif, Arial, Helvetica;
    line-height: 1.6;
    margin-top: 20px;
    margin-bottom: 20px;
}

.circle-btn {
    width: 120px;
    height: 40px;
    border: 2px solid red;
}

.circle-btn:hover {
    background-color: red;
    color: white;
    cursor: pointer;
}

/* Media Queries for Responsiveness */

/* For larger screens */
@media screen and (min-width: 768px) {
    .mainSection {
        padding: 20px;
    }

    .para {
        font-size: 18px;
    }
}

/* For smaller screens (e.g., mobile) */
@media screen and (max-width: 576px) {
    .mainSection {
        padding: 10px;
    }

    .para {
        font-size: 14px;
    }

    .circle-btn {
        width: 100px;
        height: 36px;
        font-size: 14px;
    }
}

.helpMainSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: row;
    background-color: #334455;
    padding: 20px;
  }
  
  .helpMainSection > * {
    flex: 1 1 100%;
    margin-bottom: 20px;
  }
  
  @media screen and (min-width: 768px) {
    .helpMainSection > * {
      flex: 1 1 50%;
      margin-right: 20px;
    }
  }
  
  @media screen and (min-width: 1200px) {
    .helpMainSection > * {
      flex: 1 1 25%;
      margin-right: 20px;
    }
  }
  
/* card.css */

.cardHeading {
    font-size: 17px;
    font-family: sans-serif;
}

.CardLayout:hover {
    cursor: pointer;
    background-color: crimson;
    border: white 5px solid;
    color: white;
}

.CardLayout {
    border: 1px solid black;
    height: 250px;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: red 5px solid;
    border-radius: 5px;

    align-items: center;
    transition: 0.5s;
    color: black;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.imgSection {
    height: 190px;
    width: 300px;
    color: rgb(153, 144, 144);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Responsive Styles */

/* Small devices (landscape phones) */
@media (max-width: 576px) {
    .CardLayout {
        width: 100%;
        margin: 10px 0;
    }
}

/* Medium devices (tablets) */
@media (min-width: 576px) and (max-width: 992px) {
    .CardLayout {
        width: 48%; /* Two cards per row */
        margin: 10px;
    }
}

/* Large devices (desktops) */
@media (min-width: 992px) {
    .CardLayout {
        width: 24%; /* Four cards per row */
        margin: 10px;
    }
}

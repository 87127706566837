
.content-section{
    padding: 40px;
    margin: 10px;
    flex-wrap: wrap;

    margin-right: 20px;
  margin-bottom: 110px;
    color:"white";
}


.content-para{
    font-size: 20px;
    background: rgba(193, 184, 189, 0.4);
}

.content-heading{
    font-size: 35px;
    background: rgba(193, 184, 189, 0.4);

}


@media screen and (max-width: 575px) {
    .title {
        font-size: 24px;
        /* Any other mobile-specific styles */
    }
    .image {
        width: 100%; /* Adjust image width for smaller screens */
    }
    /* Other mobile-specific styles */
}

/* Adjustments for tablets and larger screens */
@media screen and (min-width: 576px) {
    /* Add styles for larger screens */
}

/* Adjustments for medium-sized screens */
@media screen and (min-width: 768px) {
    /* Add styles for medium-sized screens */
}

/* Adjustments for larger screens */
@media screen and (min-width: 992px) {
    /* Add styles for larger screens */
}

/* Adjustments for extra-large screens */
@media screen and (min-width: 1200px) {
    /* Add styles for extra-large screens */
}
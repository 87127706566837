/* Header.css */

.header {
    background-color: #001529;
    padding: 0 20px;
  }
  
  .logo {
    display: flex;
    align-items: center;
    height: 64px;
  }
  
  .logo span {
    color: #fff;
    font-size: 24px;
  }
  
  .menu {
    display: flex;
    justify-content: flex-end;
  }
  
  /* Media Queries for Responsive Design */
  
  /* Small devices (landscape phones) */
  @media (max-width: 576px) {
    .logo span {
      font-size: 20px;
    }
    .company{
        font-size: 10px;
        width: 100%;
    }
  }
  
  /* Medium devices (tablets) */
  @media (min-width: 576px) and (max-width: 768px) {
    .logo span {
      font-size: 22px;
    }
  }
  
  /* Large devices (desktops) */
  @media (min-width: 992px) {
    .logo span {
      font-size: 26px;
    }
  }
  
/* Material.css */

/* Common styles for all screen sizes */
.mainSection {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 40px;



}

.materialSections {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.materialSection {
    flex: 1 0 150px;
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 300px;
    width: 300px;
    color:"white";
    align-items: center;
    display: flex;
    justify-content: center;
    

}





.textContent {
    margin: 0;
color: rgb(5, 9, 6);

justify-content: center;
align-items: center;

}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
    .materialSection {
        flex-basis: calc(50% - 20px);
    }
}

@media screen and (max-width: 480px) {
    .materialSection {
        flex-basis: calc(100% - 20px);
    }
    .materialHeading{
        font-size: 20px;
    }
}
